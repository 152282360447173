// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import { useLocation } from '@reach/router';
import BackButton from '../BackButton';

const Paragraphs = ({ texts }) => {
  return texts.map(({ title, content }, index) => {
    return (
      <div key={index} className={Style.contentWraper}>
        <h2 className={Style.contentTitle}>{title}</h2>
        <div
          className={Style.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    );
  });
};

const PrivacyPolicy = ({ title, description, content }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasHideBackButton = JSON.parse(queryParams.get('hideBackButton'));

  return (
    <>
      <section className={[Style.section, 'container'].join(' ')}>
        <h1 className={Style.title}>{title}</h1>
        <div
          className={Style.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Paragraphs texts={content} />
      </section>
      {!hasHideBackButton ? (
        <div className={Style.backButton}>
          <BackButton text="" />
        </div>
      ) : null}
    </>
  );
};

export default PrivacyPolicy;
