import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PrivacyPolicy from '../components/PrivacyPolicy';

const Policy = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: { policy_description: description, policy_sections: sections },
    },
  } = data;

  const sectionsProps = {
    title: page.title,
    description: description,
    content: sections.map(section => {
      return {
        title: section.policy_section_title,
        content: section.policy_section_text,
      };
    }),
  };
  return (
    <Layout
      solidHeader="white"
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={page.polylang_translations}
      useTranslationsPath={true}
    >
      <SEO {...page} pageLang={page.polylang_current_lang} />
      <PrivacyPolicy {...sectionsProps} />
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        policy_description
        policy_sections {
          policy_section_title
          policy_section_text
        }
      }
    }
  }
`;

export default Policy;
