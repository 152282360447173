// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const BackButton = ({ text, onClick }) => {
  const onBackAction = () => {
    if (typeof onClick == 'function') onClick();
    else {
      window.history.length > 2
        ? window.history.back()
        : (window.location = window.location.origin);
    }
  };

  return (
    <div onClick={onBackAction} className={[Style.backButton].join(' ')}>
      {text}
    </div>
  );
};

export default BackButton;
